@import 'Resources/scss/utils/variables';
@import 'Resources/scss/mixins/rem-calc';
@import '../../EmitterDetailsBarCellSet.module.scss';

.emitterDetailsBarKeyValueCell {
  display: flex;
  flex-direction: column;
  padding: rem-calc($cell-padding) 0;

  .title {
    color: #6e6e6e;
    margin-bottom: rem-calc(5);
    text-transform: uppercase;
  }

  .body {
    display: flex;
    align-items: center;
    margin-bottom: rem-calc(3);

    .value {
      position: relative;
      font-family: $font-family-barlow;
      font-size: rem-calc(30);
      line-height: rem-calc($value-height);
      font-weight: 600;

      .unit {
        display: inline-block;
        position: relative;
        font-size: rem-calc(24);
        line-height: rem-calc(24);
        font-weight: 400;

        &.dropdown {
          user-select: none;
          cursor: pointer;
          padding-right: rem-calc(18);

          .arrow {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            margin-top: rem-calc(2);
          }
        }
      }
    }

    .noData {
      font-family: $font-family-barlow;
      font-size: rem-calc(30);
      line-height: rem-calc($value-height);
      font-weight: 400;
      opacity: 0.4;
    }

    .loadingIndicator {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: rem-calc($value-height);
    }
  }
}

.unitMenu {
  position: relative;
  top: 0;
  left: 0;
}
