@import 'Resources/scss/mixins/rem-calc';
@import 'Resources/scss/utils/variables';

.viewModeSwitch {
  display: flex;
  justify-content: flex-start;
  width: rem-calc(32);
  height: rem-calc(18);
  padding: rem-calc(2);
  border-radius: rem-calc(9);
  background: #122040;
  cursor: pointer;
  border: 1px solid rgba(1, 1, 1, 0.04);

  &[data-is-on='true'] {
    justify-content: flex-end;

    .handle {
      background: #ff1f00;

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: rem-calc(10);
        height: rem-calc(10);
        transform: translate(-50%, -50%);
        border-radius: rem-calc(5);
        background: #ff4c33;
      }

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: rem-calc(5);
        height: rem-calc(5);
        transform: translate(-50%, -50%);
        border-radius: rem-calc(2.5);
        background: #fff;
      }
    }
  }

  .handle {
    position: relative;
    width: rem-calc(14);
    height: rem-calc(14);
    background: #fff;
    border-radius: rem-calc(7);
    background: $color-primary;

    &:after {
      content: url('./resources/graphics/icon-pause.svg');
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
