@import 'Resources/scss/mixins/rem-calc';

.topBar {
  display: flex;
  justify-content: space-between;
  min-height: rem-calc(40);
  background: #f6f6f6;

  .userNavigator {
    display: flex;
    align-items: center;
    flex-grow: 1;
    position: relative;

    .tabFilter {
      display: flex;
      align-items: center;
      gap: rem-calc(10);

      .title {
        margin-left: rem-calc(22);
        font-weight: 500;
        font-size: rem-calc(13);
        line-height: rem-calc(17);
        user-select: none;
      }

      .namespace {
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer;

        .selectTabPopup {
          position: absolute;
          top: -10px;
          background: red !important;
        }

        .expandIndicator {
          margin-left: rem-calc(10);
        }
      }
    }
  }

  .addUser {
    display: flex;
    align-items: center;
    padding: 0 rem-calc(24);

    span {
      margin-left: rem-calc(8);
    }
  }
}
