@import 'Resources/scss/utils/variables';
@import 'Resources/scss/mixins/rem-calc';

$cell-padding: 20;
$value-height: 34;

.emitterDetailsBarCellSet {
  overflow: hidden;

  .emitterDetailsCellSetContent {
    margin: rem-calc(-$cell-padding);
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    & > * {
      position: relative;
      flex-grow: 1;
      width: rem-calc(160);
      padding-left: rem-calc($cell-padding);
      padding-right: rem-calc($cell-padding);
      border-top: 1px solid #d3d9e7;
      padding-bottom: rem-calc($cell-padding);

      &::after {
        content: '';
        position: absolute;
        display: block;
        top: rem-calc($cell-padding);
        bottom: rem-calc($cell-padding);
        left: 0;
        width: 1px;
        background: #d3d9e7;
      }
    }
  }
}
