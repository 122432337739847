@import 'Resources/scss/utils/variables';
@import 'Resources/scss/mixins/rem-calc';

.biaxialChart {
  position: relative;
}

.tooltipContent {
  .tooltipRow {
    padding: rem-calc(8);

    &:not(:last-child) {
      border-bottom: 1px solid $color-separator;
    }

    & > p:not(:last-child) {
      margin-bottom: rem-calc(6);
    }
  }
}
