@import 'Resources/scss/mixins/rem-calc';

.viewModeSelector {
  display: flex;
  align-items: center;
  gap: rem-calc(8);

  .label {
    font-size: rem-calc(11);
    font-weight: 700;
    color: #425e9c;
    text-transform: uppercase;
    letter-spacing: 0.15em;

    &.highlighted {
      color: #fff;
    }
  }
}
