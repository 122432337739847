@import 'Resources/scss/mixins/rem-calc';

.dateTimePickerModalOverlay {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.dateTimePickerModal {
  $horizontalPadding: 27;

  width: rem-calc(582);
  border-radius: rem-calc(5);
  overflow: hidden;
  position: relative;

  .closeButton {
    position: absolute;
    top: rem-calc(37);
    right: rem-calc(30);
  }

  .header {
    padding: rem-calc(22) rem-calc($horizontalPadding);
    background: #0093dd;
    color: #fff;

    .title {
      font-size: rem-calc(19);
      font-weight: 700;
      line-height: rem-calc(25);
      margin-bottom: rem-calc(3);
    }

    .subtitle {
      font-weight: 500;
    }
  }

  .body {
    padding: rem-calc(22) rem-calc($horizontalPadding);
    background: #fff;
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    padding: rem-calc(18) rem-calc($horizontalPadding);
    background: #f5f5f5;

    button:not(:last-child) {
      margin-right: rem-calc(20);
    }
  }
}
