@import 'Resources/scss/utils/variables';
@import 'Resources/scss/mixins/rem-calc';

.chartSectionModalOverlay {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.chartSectionModal {
  position: relative;
  display: flex;
  flex-direction: column;

  width: calc(100vw - rem-calc(60 * 2));
  height: calc(0.6 * (100vw - rem-calc(60 * 2)));
  max-height: calc(100vh - rem-calc(60 * 2));
  padding: rem-calc(20) rem-calc(24);
  border-radius: rem-calc(5);
  background: #fff;
  overflow: hidden;

  .chartSectionModalHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: rem-calc(16);
    line-height: rem-calc(21);
    font-weight: 700;
    margin-bottom: rem-calc(20);
  }

  .chartSectionModalBody {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    // Setting height to 0 is necessary for Recharts to recalculate
    // height when srinking browser.
    // Without this recharts will only recalculate responsive
    // container when we make the browser bigger but not smaller.
    //
    // After testing, height: 0 is necessary when flex direction is
    // column. And width: 0 is necessary when flex direction is row.
    //
    // @see: https://github.com/recharts/recharts/issues/172
    height: 0;

    .controls {
      display: flex;
      gap: rem-calc(20);

      .control {
        width: 100%;
      }
    }

    .charts {
      display: flex;
      flex-grow: 1;
      margin-top: rem-calc(20);
      gap: rem-calc(20);

      // See the reason about why we need height: 0 above.
      height: 0;

      .chart {
        flex-grow: 1;

        // See the reason about why we need width: 0 above.
        width: 0;
      }

      .loadingIndicator {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
      }

      .placeholder {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: rem-calc(16);
        width: 100%;
        height: 100%;
        opacity: 0.4;
      }
    }
  }
}
