@import 'Resources/scss/utils/variables';
@import 'Resources/scss/mixins/rem-calc';

.tabNavigator {
  display: flex;

  .tab {
    display: flex;
    align-items: center;
    gap: rem-calc(8);
    position: relative;
    padding-bottom: rem-calc(11);
    font-weight: 700;
    color: var(--normal-text-color);
    user-select: none;
    cursor: pointer;
    white-space: nowrap;

    &:not(:last-child) {
      margin-right: rem-calc(24);
    }

    &.selected {
      color: var(--selected-text-color);

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: rem-calc(4);
        background: var(--underline-color);
      }
    }

    .badge {
      $radius: 8.5;

      display: inline-block;
      transform: translateY(-1.5px);
      padding: 0 rem-calc(5.5);
      height: rem-calc($radius * 2);
      border-radius: rem-calc($radius);
      color: #fff;
      background: #ff1f00;
      font-size: rem-calc(10);
      line-height: rem-calc($radius * 2 - 1.75);
      font-family: $font-family-barlow;
      font-weight: 700;
    }

    &.more {
      .moreMenu {
        display: none;

        button {
          color: var(--normal-text-color);
        }
      }

      &:hover {
        .moreMenu {
          display: inherit;
        }
      }
    }
  }
}
