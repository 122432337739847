@import 'Resources/scss/utils/variables';
@import 'Resources/scss/mixins/rem-calc';

.radioButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: rem-calc(20);
  height: rem-calc(20);
  background: #fff;
  border-radius: rem-calc(10);
  border: 1px solid #b2b7c7;
  cursor: pointer;

  &.selected {
    border-color: $color-primary;
  }

  .dot {
    width: rem-calc(14);
    height: rem-calc(14);
    border-radius: rem-calc(7);
    background: $color-primary;
  }
}
