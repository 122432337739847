@import 'Resources/scss/mixins/rem-calc';
@import 'Resources/scss/utils/variables.scss';

.mapPopup {
  :global {
    .mapboxgl-popup-content {
      padding: rem-calc(12);
      font-family: $font-family-red-hat-display;
      font-size: rem-calc(13);
      font-weight: 500;
      line-height: rem-calc(17);
      border-radius: rem-calc(5);
    }
  }

  .closeButton {
    position: absolute;
    top: rem-calc(9);
    right: rem-calc(7);

    &:hover {
      opacity: 0.4;
    }
  }

  .flag {
    margin-right: rem-calc(8);
    border: 1px solid #e4e4e4;
    border-radius: 3px;
  }

  .title {
    font-family: $font-family-barlow;
    font-size: rem-calc(16);
    font-weight: 600;
    line-height: rem-calc(19);
    padding-right: rem-calc(28);
  }

  hr {
    margin: rem-calc(10) 0;
  }

  strong {
    font-weight: 700;
  }

  .section {
    p {
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: rem-calc(8);
      }
    }
  }
}
