@import 'Resources/scss/utils/variables';
@import 'Resources/scss/mixins/rem-calc';

$emitter-details-bar-value-cell-padding: 20;
$value-height: 34;

.emitterDetailsBarValueCell {
  display: flex;
  flex-direction: column;
  padding: rem-calc($emitter-details-bar-value-cell-padding) 0;

  .title {
    color: #6e6e6e;
    margin-bottom: rem-calc(5);
    text-transform: uppercase;
  }

  .body {
    display: flex;
    align-items: center;
    margin-bottom: rem-calc(3);

    .value {
      font-family: $font-family-barlow;
      font-size: rem-calc(30);
      line-height: rem-calc($value-height);
      font-weight: 600;

      .unit {
        font-size: rem-calc(24);
        line-height: rem-calc(24);
        font-weight: 400;
      }
    }

    .noData {
      font-family: $font-family-barlow;
      font-size: rem-calc(30);
      line-height: rem-calc($value-height);
      font-weight: 400;
      opacity: 0.4;
    }

    .loadingIndicator {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: rem-calc($value-height);
    }
  }
}

.emitterDetailsValueCellSet {
  overflow: hidden;

  .emitterDetailsValueCellSetContent {
    margin: rem-calc(-$emitter-details-bar-value-cell-padding);
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .emitterDetailsBarValueCell {
      position: relative;
      flex-grow: 1;
      width: rem-calc(160);
      padding-left: rem-calc($emitter-details-bar-value-cell-padding);
      padding-right: rem-calc($emitter-details-bar-value-cell-padding);
      border-top: 1px solid #d3d9e7;
      padding-bottom: rem-calc($emitter-details-bar-value-cell-padding);

      &::after {
        content: '';
        position: absolute;
        display: block;
        top: rem-calc($emitter-details-bar-value-cell-padding);
        bottom: rem-calc($emitter-details-bar-value-cell-padding);
        left: 0;
        width: 1px;
        background: #d3d9e7;
      }
    }
  }
}
