@import 'Resources/scss/utils/variables';
@import 'Resources/scss/mixins/rem-calc';

* {
  box-sizing: border-box !important;
}

.alertsBar {
  display: flex;
  flex-direction: column;
  width: rem-calc(670);
  height: 100%;
  background: white;
}
