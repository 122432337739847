@import 'Resources/scss/mixins/rem-calc';

.videoFeedSectionBody {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: rem-calc(24);

  .video {
    flex-grow: 1;
    width: rem-calc(240);

    .title {
      font-size: rem-calc(16);
      font-weight: 700;
      line-height: rem-calc(21);
      margin-bottom: rem-calc(10);
    }

    .thumbnail {
      position: relative;
      background: #000;
      height: 0;
      padding-bottom: 56.25%; // 16:9 aspect ratio
      cursor: pointer;

      .iconPlay {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        &:before {
          content: url('./resources/graphics/icon-play.svg');
        }
      }
    }
  }
}
