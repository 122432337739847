input {
  appearance: none;
  border-radius: rem-calc(5);
  padding: 0 rem-calc(13);
  outline: 0 !important;
  height: rem-calc(42);
  border: 1px solid #d3d9e7;
  font-weight: 500;

  &:hover {
    border-color: #9397a1;
  }

  &:focus {
    border-color: #009fe3;
  }

  &::placeholder {
    opacity: 1; // Mozilla fix
    color: #8b93a4;
  }

  &:disabled {
    border-color: #e1e6f0;
    background: #fbfcfd;
    cursor: not-allowed;

    &:hover,
    &:focus {
      border-color: #e1e6f0;
    }

    &::placeholder {
      opacity: 0;
      color: rgba(255, 255, 255, 0);
    }
  }

  &.error {
    border-color: #ff1f00;
  }
}

.form-control {
  label {
    display: block;
    margin-bottom: rem-calc(10);
  }

  .error-info {
    display: block;
    margin-top: rem-calc(5);
    color: #ff1f00;
  }

  &.disabled {
    label {
      color: #9facc7;
    }
  }
}
