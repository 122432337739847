@import 'Resources/scss/utils/variables';
@import 'Resources/scss/mixins/rem-calc';

.menuPickerPopup {
  $radius: 5;

  z-index: 9;
  background: #ffffff;
  border-radius: rem-calc($radius);
  position: absolute;
  width: rem-calc(200);
  display: flex;
  flex-direction: column;
  left: rem-calc(-153);
  top: rem-calc(20);
  box-shadow: 0 0 rem-calc(10) 0 rgba(#000, 0.1);
  padding: rem-calc(2);

  button {
    padding: rem-calc(10) 0 rem-calc(10) rem-calc(10);
    color: #000;

    &.menu {
      border-radius: rem-calc($radius - 1);

      &.selected {
        color: $color-primary !important;
      }
    }

    &:hover {
      background: #f1f3f7;
    }
  }
}

.menuPickerPopup:after {
  content: '';
  position: absolute;
  top: 0;
  left: rem-calc(163);
  margin-left: rem-calc(-5);
  margin-top: rem-calc(-5);
  width: 0;
  z-index: 1;
  height: 0;
  border-bottom: solid rem-calc(5) #fff;
  border-left: solid rem-calc(5) transparent;
  border-right: solid rem-calc(5) transparent;
}
