@import 'Resources/scss/mixins/rem-calc';

.alertRowMenu {
  $radius: 5;

  background: #ffffff;
  border-radius: rem-calc($radius);
  position: absolute;
  width: rem-calc(200);
  display: flex;
  flex-direction: column;
  left: rem-calc(-173);
  top: rem-calc(16);
  box-shadow: 0 0 rem-calc(10) 0 rgba(#000, 0.1);
  padding: rem-calc(2);

  button {
    padding: rem-calc(10) 0 rem-calc(10) rem-calc(10);

    &.dismiss {
      border-radius: rem-calc($radius - 1);
    }

    &:hover {
      background: #f1f3f7;
    }
  }
}

.alertRowMenu:after {
  content: '';
  position: absolute;
  top: 0;
  left: rem-calc(183);
  margin-left: rem-calc(-5);
  margin-top: rem-calc(-5);
  width: 0;
  z-index: 1;
  height: 0;
  border-bottom: solid rem-calc(5) #fff;
  border-left: solid rem-calc(5) transparent;
  border-right: solid rem-calc(5) transparent;
}
