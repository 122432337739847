@import 'Resources/scss/utils/variables';
@import 'Resources/scss/mixins/rem-calc';

*,
*:before,
*:after {
  box-sizing: border-box;
}

:root {
  min-height: 100vh;

  background: #fff;
  font-family: $font-family-red-hat-display;
  font-weight: 500;
  font-size: 100%;
  color: #000;

  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased; // For solving bug font appear bolder on Chrome and any other Webkit browser
  -moz-osx-font-smoothing: grayscale; // For solving bug font appear bolder on Firefox, MacOS

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); // Remove default highlight on Safari, iOS
}

body {
  font-size: rem-calc(13);
  line-height: rem-calc(17);
}

a,
button {
  user-select: none;
  cursor: pointer !important;
}

/**
 * By default input, select, textarea, button does not inherit font from its container, it makes
 * the font-family that's defined in the body is not inherited. To fix this we have to set the
 * font-family to inherit.
 */
input,
select,
textarea,
button {
  font-family: inherit;
}

button {
  all: unset;

  // Fallback
  background: none;
  border: 0;
}

hr {
  all: unset;
  border: 0;
  height: 1px;
  background: $color-separator;
  display: block;
}

svg {
  display: block;
}

abbr {
  border: none;
  text-decoration: none;
}

.expanded {
  width: 100%;
}
