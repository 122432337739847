@import 'Resources/scss/mixins/rem-calc';

.stepEmail {
  padding: rem-calc(30);

  .loadingIndicator {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}
