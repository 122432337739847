@import 'Resources/scss/mixins/rem-calc';

.dateTimePicker {
  display: flex;

  .dateColumn {
    display: flex;
    flex-direction: column;
    margin-left: rem-calc(-10);
  }

  .timeColumn {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    padding-left: rem-calc(14);
  }
}
