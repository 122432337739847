@import 'Resources/scss/utils/variables';
@import 'Resources/scss/mixins/rem-calc';

.datePicker {
  .datePickerHeader {
    display: flex;
    width: 100%;
  }

  .datePickerBody {
    margin-top: rem-calc(20);
  }

  .monthPicker {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: space-between;
    margin-right: rem-calc(20);
    font-size: rem-calc(16);
    font-weight: 500;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: rem-calc(29);
      height: rem-calc(29);

      &:disabled {
        cursor: not-allowed !important;
      }
    }
  }

  .yearPicker {
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid #d3d9e7;
    border-radius: rem-calc(3);
    overflow: hidden;

    select {
      padding-left: rem-calc(12);
      padding-right: rem-calc(27);
      appearance: none;
      border: 0;
      outline: 0;
      font-weight: 500;
      height: rem-calc(29);
      cursor: pointer;
    }

    svg {
      position: absolute;
      right: rem-calc(9);
      pointer-events: none;
    }
  }

  .dateCalendarPicker {
    $number-of-rows: 6;
    $table-cell-width: 36;
    $header-cell-height: 30;
    $body-cell-height: 36;

    height: rem-calc(calc($header-cell-height + $number-of-rows * $body-cell-height));

    table {
      td,
      th {
        width: rem-calc($body-cell-height);
        text-align: center;
        font-size: rem-calc(13);

        span {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
        }
      }

      th {
        height: rem-calc($header-cell-height);
        color: #6e6e6e;
      }

      td {
        height: rem-calc($body-cell-height);
        font-weight: 500;
        cursor: pointer;
        user-select: none;

        &:hover {
          background: $color-highlighted;
        }

        &.disabled {
          color: #d9d9d9;
          cursor: not-allowed;

          &:hover {
            background: none;
          }
        }

        &.selected {
          background: $color-selected;
        }
      }
    }
  }
}
