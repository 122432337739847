@import 'Resources/scss/mixins/rem-calc';

.calendarNavigator {
  $button-size: 24;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: rem-calc($button-size);
  padding: 0 rem-calc($button-size);
  margin-bottom: rem-calc(12);
  text-align: center;
  font-size: rem-calc(16);

  .calendarNavigatorButton {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem-calc($button-size);
    height: rem-calc($button-size);

    &.left {
      left: 0;
    }

    &.right {
      right: 0;
    }
  }
}
