@import 'Resources/scss/utils/variables';
@import 'Resources/scss/mixins/rem-calc';

.menu {
  $radius: 4;

  position: relative;
  background: #fff;
  border-radius: 4 * 1px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  user-select: none;

  &.hasArrow::before {
    content: '';
    position: absolute;
    top: 0;
    left: rem-calc(8 + 27);
    margin-left: rem-calc(-8);
    margin-top: rem-calc(-8);
    width: 0;
    z-index: 1;
    height: 0;
    border-bottom: solid rem-calc(8) #fff;
    border-left: solid rem-calc(8) transparent;
    border-right: solid rem-calc(8) transparent;
    transform: translate(var(--pointer-correction));
  }

  .searchControl {
    padding: rem-calc(10);
    min-width: rem-calc(223);

    .searchInput {
      position: relative;

      .searchInputIcon {
        position: absolute;
        right: rem-calc(13);
        top: 50%;
        transform: translateY(-50%);
        cursor: default;
      }

      input {
        padding-right: rem-calc(42);
        width: 100%;
      }
    }
  }

  li {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: rem-calc(37);
    white-space: nowrap;
    padding: 0 rem-calc(13);
    cursor: pointer;
    font-weight: 500;

    .hasScroll {
      &:after {
        display: flex;
        justify-content: center;
        width: 100%;
      }
    }

    ul {
      overflow-y: auto;
      max-height: rem-calc(300);
      position: relative;
    }

    .hasSearchControl &:first-child {
      border-top-left-radius: $radius * 1px;
      border-top-right-radius: $radius * 1px;
    }

    &:last-child {
      border-bottom-left-radius: $radius * 1px;
      border-bottom-right-radius: $radius * 1px;
    }

    & > span {
      padding-right: rem-calc(13);
      min-width: rem-calc(120);

      .highlight {
        color: $color-primary;
        background: none;
      }
    }

    &:hover {
      background: $color-highlighted;

      > .menu {
        display: block;
      }
    }
  }

  .menu {
    position: absolute;
    top: 0;
    left: 100%;
    display: none;
  }
}
