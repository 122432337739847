@import 'Resources/scss/mixins/rem-calc';

.timePicker {
  display: flex;
  align-items: center;
  font-size: rem-calc(30);
  font-weight: 500;

  .timePickerSegment {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &.hour {
      margin-right: rem-calc(5);
    }

    &.minute {
      margin-left: rem-calc(5);
    }

    &.timezone {
      margin-left: rem-calc(9);
    }

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      height: rem-calc(36);
      width: rem-calc(60);
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: rem-calc(29);
      height: rem-calc(29);
    }
  }

  .timePickerColon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: rem-calc(36);
    margin-bottom: rem-calc(4);
  }
}
