@import 'Resources/scss/mixins/rem-calc';

.controlBar {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  height: rem-calc(40);
  background: #f6f6f6;

  .inviteButton {
    display: flex;
    align-items: center;
    padding: 0 rem-calc(24);

    span {
      margin-left: rem-calc(8);
    }
  }
}
