@import 'Resources/scss/mixins/rem-calc';

.userInfoPopup {
  z-index: 999;
  background: #ffffff;
  border: 1px solid #d3d9e7;
  border-radius: rem-calc(5);
  position: absolute;
  left: calc(100% + 0.5rem + 0.3125rem);
  width: max-content;
  top: rem-calc(-10);
  padding: rem-calc(12) rem-calc(15);

  .name {
    font-size: rem-calc(14);
    color: #000000;
  }

  .email {
    margin-top: rem-calc(8);
  }

  .roles {
    margin-top: rem-calc(16);
    display: flex;
    flex-wrap: wrap;
    gap: rem-calc(8);
    max-width: rem-calc(364);

    .role {
      background: #efefef;
      padding: rem-calc(4) rem-calc(8);
      border-radius: rem-calc(4);
      font-size: rem-calc(12);
      color: #5f5f5f;
    }
  }

  .altId {
    font-size: rem-calc(12);
    margin-top: rem-calc(16);
    color: #aaaaaa;
  }

  $triangle-top: rem-calc(14);
  $triangle-size: rem-calc(5);

  $triangle-before-margin-left: calc(
    -#{$triangle-size} - 0.0625rem
  ); // 1px more than :after to create the border
  $triangle-after-margin-left: calc(-#{$triangle-size});

  // triangle border
  &:before {
    content: '';
    position: absolute;
    top: $triangle-top;
    left: 0;
    margin-left: $triangle-before-margin-left;
    width: 0;
    z-index: 1;
    height: 0;
    border-top: solid $triangle-size transparent;
    border-bottom: solid $triangle-size transparent;
    border-right: solid $triangle-size #d3d9e7;
  }

  // inner triangle
  &:after {
    content: '';
    position: absolute;
    top: $triangle-top;
    left: 0;
    margin-left: $triangle-after-margin-left;
    width: 0;
    z-index: 2;
    height: 0;
    border-top: solid $triangle-size transparent;
    border-bottom: solid $triangle-size transparent;
    border-right: solid $triangle-size #fff;
  }
}
