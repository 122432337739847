@import 'Resources/scss/mixins/rem-calc';

.toastsWrapper {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin-bottom: rem-calc(20);
  display: flex;
  justify-content: center;

  .toasts {
    .toast:first-child {
      margin-top: 0;
    }

    .toast {
      position: relative;
      margin: 0 auto;
      margin-top: rem-calc(-38);
      width: 100%;
      display: flex;
      align-items: center;
      border-radius: rem-calc(6);
      overflow: hidden;
      
      &.red {
        background: #ff1f00;
        border: 1px solid #ff1f00;
        .content {
          background: #fff0ee;
        }
      }

      &.green {
        background: #00DD8D;
        border: 1px solid #00DD8D;
        .content {
          background: #EBFFF8;
        }
      }
      
      .icon {
        width: rem-calc(43);
        padding: 0 rem-calc(12);
      }

      .content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: rem-calc(11) rem-calc(15) rem-calc(11) rem-calc(14);
        gap: rem-calc(10);
        .iconClose {
          margin-right: rem-calc(15);
        }
      }
    }
  }
}
