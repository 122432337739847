@import 'Resources/scss/mixins/rem-calc';

.shareEmitterAccessModalBody {
  display: flex;
  flex-direction: column;
  height: 100%;

  .stepContent {
    flex-grow: 1;
    overflow-y: auto;
  }
}

.shareEmitterAccessModalBodyFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .stepNumber {
    font-size: rem-calc(14);
  }

  .stepControl {
    display: flex;
    align-items: center;
    gap: rem-calc(20);
  }
}
