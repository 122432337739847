@import 'Resources/scss/mixins/rem-calc';

.body {
  min-height: rem-calc(390);
  padding: rem-calc(30);

  .rowInput {
    width: 100%;

    .input {
      width: 100%;

      .headline {
        .title {
          size: rem-calc(13);
          line-height: rem-calc(17);
          margin-bottom: rem-calc(10);
        }

        .required {
          margin-left: rem-calc(5);
          color: #ff0000;
        }
      }

      input {
        height: rem-calc(42);
        width: 100%;
      }
    }
  }
}

.controlBar {
  display: flex;
  justify-content: flex-end;
  gap: rem-calc(20);
}
