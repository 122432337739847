@import 'Resources/scss/utils/variables.scss';
@import 'Resources/scss/mixins/rem-calc';

.stabilitySectionBody {
  display: flex;
  gap: rem-calc(24);

  .column {
    flex: 1;

    .title {
      font-size: rem-calc(16);
      line-height: rem-calc(21);
    }

    .value {
      font-family: $font-family-barlow;
      font-size: rem-calc(30);
      font-weight: 600;
      line-height: rem-calc(36);
      height: rem-calc(36);

      .unit {
        font-size: rem-calc(24);
        font-weight: 400;
      }
    }

    .noData {
      font-family: $font-family-barlow;
      font-size: rem-calc(30);
      font-weight: 400;
      line-height: rem-calc(36);
      height: rem-calc(36);
      opacity: 0.4;
    }

    .graph {
      margin-top: rem-calc(16);
    }
  }
}
