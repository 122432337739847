@use 'sass:math';
@import 'Resources/scss/mixins/rem-calc';

.timelineBarContainer {
  $thumb-height: 17;
  $tick-height: 11;
  $tick-label-height: 13;
  $tick-label-offset: 4;

  $height: calc($tick-label-height + $tick-label-offset + math.max($thumb-height, $tick-height));

  $line-color: #354c80;

  position: relative;
  height: rem-calc($height);

  .timelineBar {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: rem-calc($thumb-height);
    cursor: pointer;

    .timelineBarLine {
      position: absolute;
      left: 0;
      top: rem-calc(calc(($thumb-height - 1) / 2));
      width: 100%;
      height: 1px;
      background: $line-color;
    }

    .timelineBarThumb {
      position: absolute;
      left: 100%;
      top: 0;
      width: 1px;
      height: rem-calc($thumb-height);
      background: #fff;
      cursor: move;
    }
  }

  .timelineBarTick {
    position: absolute;
    top: rem-calc(calc(($tick-height - 1) / 2 * -1));
    width: 1px;
    height: rem-calc($tick-height);
    background: $line-color;

    .timelineBarTickLabel {
      position: absolute;
      font-size: rem-calc(11);
      font-weight: 700;
      margin-top: rem-calc($tick-label-offset);
      line-height: rem-calc($tick-label-height);
      height: rem-calc($tick-label-height);
      color: #899ecd;
      white-space: nowrap;

      @extend .center;

      &.center {
        transform: translate(-50%, 100%);
      }

      &.left {
        transform: translate(0, 100%);
      }

      &.right {
        transform: translate(-100%, 100%);
      }
    }
  }

  .timelineBarTooltip {
    position: absolute;
    bottom: rem-calc(20);
    transform: translateX(-50%);

    .content {
      padding: rem-calc(2) rem-calc(8);
      border-radius: rem-calc(1);
      background: #fff;
      color: #000;
      text-wrap: nowrap;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    }
  }
}
