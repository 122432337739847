@import 'Resources/scss/mixins/rem-calc';

.accordion {
  overflow: hidden;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 rem-calc(20);
    height: rem-calc(36);
    background: #f6f6f6;
    border-bottom: 1px solid #d3d9e7;
    border-top: 1px solid #d3d9e7;
    user-select: none;
    cursor: pointer;

    .title {
      display: flex;
      align-items: center;
      gap: rem-calc(10);
    }
  }
}
