@use 'sass:math';
@import 'Resources/scss/utils/variables';
@import 'Resources/scss/mixins/rem-calc';

$switch-width: 40;
$switch-height: 24;
$switch-inset: 4;

.switch {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: rem-calc($switch-width);
  height: rem-calc($switch-height);
  padding: 0 rem-calc($switch-inset);
  border-radius: rem-calc(math.div($switch-height, 2));
  cursor: pointer;
  overflow: hidden;

  &.disabled {
    background-color: #f1f3f7 !important;
    cursor: not-allowed;
  }

  .handle {
    $handle-radius: math.div($switch-height - ($switch-inset * 2), 2);
    width: rem-calc($handle-radius * 2);
    height: rem-calc($handle-radius * 2);
    background-color: #fff;
    border-radius: rem-calc($handle-radius);
  }
}
