@use 'sass:math';
@import 'Resources/scss/utils/variables';
@import 'Resources/scss/mixins/rem-calc';

.reportsPageContent {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: rem-calc(46);

  > .header {
    position: fixed;
    top: 0;
    z-index: 9;
    width: 100%;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    height: rem-calc(46);
    background: #fff;
    font-family: $font-family-barlow;
    font-size: rem-calc(16);
    padding: 0 rem-calc(20);
  }

  > .body {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: rem-calc(20);

    .control {
      display: flex;
      gap: rem-calc(20);

      .select {
        width: rem-calc(119);
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      flex: 1;
      margin-top: rem-calc(20);

      .emptyPlaceholderContainer {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .emptyPlaceholder {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          font-size: rem-calc(16);
          color: #b2b7c7;
          margin-top: rem-calc(-130);
        }
      }

      .chartSection {
        background: #fff;
        padding: rem-calc(20);
        border-radius: rem-calc(6);

        &:not(:last-child) {
          margin-bottom: rem-calc(20);
        }

        > .header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: rem-calc(20);

          .title {
            text-transform: uppercase;
            font-size: rem-calc(13);
            letter-spacing: rem-calc(math.div(13, 10));
            color: #6e6e6e;
          }

          .legendContainer {
            display: flex;
            gap: rem-calc(24);

            .legend {
              .dot {
                $radius: 4;

                display: inline-block;
                width: rem-calc($radius * 2);
                height: rem-calc($radius * 2);
                border-radius: rem-calc($radius);
                margin-right: rem-calc(6);
              }
            }
          }
        }

        > .body {
          .bodyPlaceholder {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            font-size: rem-calc(14);
          }
        }
      }

      .chartSectionGroup {
        .chartSection {
          &:first-child {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }

          &:last-child {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
          }

          &:not(:first-child):not(:last-child) {
            border-radius: 0;
          }

          margin-bottom: 0;
        }
      }
    }
  }
}
