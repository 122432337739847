@import 'Resources/scss/mixins/rem-calc';

.compliantPopup {
  position: absolute;
  z-index: 999;
  left: 0;
  margin-top: rem-calc(10);
  width: 100%;
  padding: rem-calc(10);
  background: #fff;
  border: 1px solid #d3d9e7;
  border-radius: rem-calc(5);

  display: flex;
  flex-direction: column;
  gap: rem-calc(9);

  .checklist {
    width: 100%;
    display: flex;
    align-items: center;
    gap: rem-calc(10);

    p {
      font-size: rem-calc(13);

      &.correct {
        color: #000;
      }

      &.wrong {
        color: #8d8d8d;
      }
    }
  }
}
