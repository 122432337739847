@import 'Resources/scss/utils/variables';
@import 'Resources/scss/mixins/rem-calc';

.row {
  position: relative;
  display: flex;
  align-items: center;
  padding: rem-calc(14) 0;
  font-size: rem-calc(13);
  color: #6e6e6e;
  border-bottom: rem-calc(1) solid #d3d9e7;

  .userProfile {
    width: rem-calc(210);
    margin-left: rem-calc(22);

    .nameWrapper {
      position: relative;
      max-width: max-content;

      .name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        font-size: rem-calc(15);
        color: #000000;
        cursor: pointer;

        &.selected {
          color: $color-primary;
        }

        &:hover {
          color: $color-primary;
        }
      }
    }

    .email {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      margin-top: rem-calc(3);
    }
  }

  .namespace {
    width: rem-calc(156);
    margin-left: rem-calc(20);
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .role {
    width: rem-calc(152);
    margin-left: rem-calc(20);
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .option {
    flex-grow: 1;
    margin-left: rem-calc(20);

    .menu {
      position: relative;

      .iconDots {
        cursor: pointer;
      }
    }
  }

  .loading {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 147, 221, 0.2);
  }
}
