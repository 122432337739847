@import 'Resources/scss/utils/variables';
@import 'Resources/scss/mixins/rem-calc';

$horizontal-inset: rem-calc(20);

.sideBar {
  height: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;

  .expandButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem-calc(22);
    height: rem-calc(40);
    position: absolute;
    bottom: rem-calc(210);
    right: 0;
    transform: translateX(100%);
    background: #fff;
    border-radius: 0 rem-calc(5) rem-calc(5) 0;
    transition: opacity ease-in-out 0.5s;

    &.hidden {
      opacity: 0;
    }
  }

  .sideBarHeader {
    flex-grow: 0;
    display: flex;
    position: relative;
    // align-items: center;

    $height: rem-calc(46);

    height: $height;
    min-height: $height;
    color: white;
    background: #1f2c4a;

    .yellowBar {
      width: 8px;
      background: #fff500;
    }

    .blueBar {
      width: 8px;
      background: #0093dd;
    }

    .logoContainer {
      margin-left: rem-calc(16);
      height: max-content;
      padding-bottom: 0 !important;
      display: flex;
      align-items: center;
      height: 100%;

      .logoContent {
        margin-top: rem-calc(
          2
        ); // although it's already center but the primary text need go down a bit to balance the whitespace

        sub {
          margin-top: rem-calc(8);
          font-size: rem-calc(7.5);
          text-transform: uppercase;
          font-weight: 500;
          letter-spacing: 0.2em;
          line-height: rem-calc(7);
          display: block;
          margin-left: 0.5px;
        }
      }
    }

    .collapseButton {
      $collapse-button-size: 20;
      $icon-width: 10;

      display: flex;
      width: rem-calc($collapse-button-size);
      height: rem-calc($collapse-button-size);
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 50%;
      right: $horizontal-inset;

      $offset: calc(($collapse-button-size - $icon-width) / 2);

      transform: translate(rem-calc($offset), -50%);
      transition: opacity ease-in-out 0.5s;

      &.hidden {
        opacity: 0;
      }
    }
  }

  .sideBarBody {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: rem-calc(16) 0;
    overflow-y: auto;
  }

  .fleetNavigatorSection {
    padding-bottom: rem-calc(15);
  }

  .fleetNavigatorHeader {
    flex-grow: 0;
    padding: 0 $horizontal-inset;
    margin-bottom: rem-calc(8);

    .fleetNavigatorHeaderContent {
      display: flex;
      justify-content: space-between;
      text-transform: uppercase;

      font-size: rem-calc(11);
      font-weight: 400;
      line-height: rem-calc(15);
      letter-spacing: 0.29em;

      padding: rem-calc(14) 0;
    }
  }

  .fleetNavigator {
    .fleetNavigatorItem {
      display: flex;
      align-items: center;
      position: relative;
      padding: rem-calc(8) $horizontal-inset rem-calc(8) rem-calc(32);
      cursor: pointer;

      &:hover {
        background: $color-highlighted;
      }

      &.selected {
        background: $color-selected;
      }

      font-family: $font-family-barlow;
      font-size: rem-calc(16);
      font-weight: 500;
      line-height: rem-calc(19);

      .statusIndicator {
        position: absolute;

        display: inline-block;

        $radius: 3px;
        width: $radius * 2;
        height: $radius * 2;
        border-radius: $radius;

        left: $horizontal-inset;
        top: 50%;
        margin-top: -$radius;

        @extend .online;

        &.online {
          background: #0093dd;
        }

        &.offline {
          background: #b2b7c7;
        }

        &.lowData {
          background: #ff8c00;
        }
      }
    }
  }

  .sideBarMenu {
    display: flex;
    align-items: center;
    height: rem-calc(50);
    padding: 0 $horizontal-inset;
    background: #f1f3f7;
    color: inherit;
    position: relative;

    font-size: rem-calc(13);
    font-weight: 500;

    cursor: pointer;

    &:not(:last-child) {
      margin-bottom: 2px;
    }

    .sideBarMenuIcon {
      margin-right: rem-calc(5);
    }
  }

  .sideBarClock {
    display: flex;
    align-items: center;
    height: rem-calc(80);
    padding: 0 rem-calc(16);
    background: #122040;

    .sideBarContent {
      .sideBarClockTime {
        font-family: $font-family-barlow;
        font-weight: 400;
        font-size: rem-calc(25);
        line-height: rem-calc(30);
        color: #fff;
      }

      .sideBarClockDate {
        margin-top: 2px;
        font-weight: 700;
        font-size: rem-calc(12);
        line-height: rem-calc(16);
        text-transform: uppercase;
        color: #899ecd;
      }
    }
  }
}
