@import 'Resources/scss/mixins/rem-calc';

.multiSectionElement {
  .multiSectionElementHeader {
    font-size: rem-calc(13);
    font-weight: 700;
    text-transform: none;
    letter-spacing: normal;
    position: relative;
    padding-bottom: rem-calc(11);
    border-bottom: 1px solid #d3d9e7;
    margin-bottom: rem-calc(20);
    display: flex;
    align-items: center;

    &:before {
      content: '';
      width: rem-calc(5);
      height: rem-calc(5);
      margin-right: rem-calc(6);
      display: block;

      // Should be 50% of width, but we made i t 100% of width to prevent rounding error
      border-radius: rem-calc(10);
      background: #000;
    }
  }

  &:not(:last-child) {
    margin-bottom: rem-calc(25);
  }

  .multiSectionElementBody {
    // Reserved for future use
  }
}
