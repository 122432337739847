@import 'Resources/scss/utils/variables';
@import 'Resources/scss/mixins/rem-calc';

.selectPopup {
  z-index: 1000;
  position: absolute;
  top: rem-calc(38);
  left: rem-calc(21);
  width: rem-calc(273);
  background: #fff;
  border-radius: rem-calc(4);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

  &.noSearchField {
    .scrollableItems {
      border-radius: rem-calc(4) !important;
    }
  }

  .searchField {
    padding: rem-calc(10);
    position: relative;

    input {
      width: 100%;
      padding: rem-calc(10) rem-calc(40) rem-calc(10) rem-calc(13);
      font-size: rem-calc(13);
      line-height: rem-calc(17);

      &::placeholder {
        opacity: 1; // Mozilla fix
        color: #8b93a4;
      }

      background-image: url(./resources/graphics/icon-search.svg);
      background-repeat: no-repeat;
      background-position: 95%;
    }

    input[type='search']::-webkit-search-decoration,
    input[type='search']::-webkit-search-cancel-button,
    input[type='search']::-webkit-search-results-button,
    input[type='search']::-webkit-search-results-decoration {
      display: none;
    }

    .searchIcon {
      position: absolute;
      top: rem-calc(18);
      right: rem-calc(23);
    }
  }

  .scrollableItems {
    overflow-y: auto;
    border-radius: 0 0 rem-calc(4) rem-calc(4);

    .item {
      display: flex;
      align-items: center;
      width: 100%;
      height: rem-calc(37);
      padding: 0 rem-calc(13);
      cursor: pointer;

      &:hover {
        color: var(--hover-color);
        background: var(--hover-background);
      }

      &.selected {
        color: var(--select-color);
        background: var(--select-background);
      }

      span {
        font-size: rem-calc(13);
        line-height: rem-calc(17);
        font-weight: 500;
      }

      .highlight {
        color: $color-primary;
        background: none;
      }
    }
  }
}

.selectPopup:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  margin-left: rem-calc(22);
  margin-top: rem-calc(-6);
  width: 0;
  z-index: 1;
  height: 0;
  border-bottom: solid rem-calc(6) #fff;
  border-left: solid rem-calc(6) transparent;
  border-right: solid rem-calc(6) transparent;
}
