@import 'Resources/scss/mixins/rem-calc';

.emitterDetailsBarMenu {
  border-bottom: 1px solid #d3d9e7;
  margin-bottom: rem-calc(20);

  .menuItemSelector {
    display: inline-block;
    position: relative;
    padding-bottom: rem-calc(10);

    .title {
      display: flex;
      align-items: center;
      gap: rem-calc(10);
      font-weight: 700;
      cursor: pointer;
    }

    .popup {
      position: absolute;
      left: 0;
      bottom: 0;

      & > div {
        // TODO: Rewrite Edwin's SelectPopup library.
        //
        // So Edwin did a mistake by adding position to the popup.
        // We will reset it here temporarily because we need to
        // fix it in multiple screens to fully fix it.
        top: 0;
        left: 0;
      }
    }
  }
}
