@import 'Resources/scss/utils/variables';
@import 'Resources/scss/mixins/rem-calc';

.alertsBarContent {
  $gutter: 20;
  $status-column-width: 54;
  $summary-column-width: 315;
  $source-column-width: 130;
  $date-column-width: 125;

  $row-padding-vertical: 12;

  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .head {
    display: flex;
    font-size: rem-calc(13);
    font-weight: 700;
    line-height: rem-calc(17.2);
    color: #6e6e6e;
    padding: rem-calc(10) 0;
    border-top: rem-calc(1) solid #d3d9e7;
    border-bottom: rem-calc(1) solid #d3d9e7;

    .status {
      width: rem-calc($status-column-width);
    }

    .summary {
      width: rem-calc($summary-column-width);
      padding-right: rem-calc($gutter);
    }

    .source {
      width: rem-calc($source-column-width);
      padding-right: rem-calc($gutter);
    }

    .date {
      width: rem-calc($date-column-width);
      padding-right: rem-calc($gutter);
    }
  }

  .body {
    flex-grow: 1;
    overflow-y: auto;

    .dataRow {
      display: flex;
      align-items: center;
      border-bottom: rem-calc(1) solid #d3d9e7;

      .status {
        width: rem-calc($status-column-width);

        .statusDot {
          $radius: 5;
          width: rem-calc($radius * 2);
          height: rem-calc($radius * 2);
          border-radius: rem-calc($radius);
          margin: auto;

          &.red {
            background: #ff1f00;
          }
          &.yellow {
            background: #ffb900;
          }
          &.blue {
            background: #0093dd;
          }
          &.grey {
            background: #343332;
          }
        }
      }

      .summary {
        padding: rem-calc($row-padding-vertical) rem-calc($gutter) rem-calc(15) 0;
        width: rem-calc($summary-column-width);

        .title {
          color: #000000;
          font-size: rem-calc(15);
          line-height: rem-calc(20);
        }

        .description {
          color: #6e6e6e;
        }
      }

      .source {
        width: rem-calc($source-column-width);
        padding-right: rem-calc($gutter);
        color: #6e6e6e;
      }

      .date {
        width: rem-calc($date-column-width);
        padding-right: rem-calc($gutter);
        color: #6e6e6e;
      }

      .menu {
        position: relative;
        flex-grow: 1;

        .iconDots {
          cursor: pointer;
        }
      }
    }

    .loadingNextPageIndicatorRow {
      display: flex;
      justify-content: center;
      padding: rem-calc($row-padding-vertical) 0;
    }
  }
}
