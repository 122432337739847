@import 'Resources/scss/utils/variables';
@import 'Resources/scss/mixins/rem-calc';

.alertsBarHeader {
  $horizontal-inset: 22;

  position: relative;
  padding-top: rem-calc(17);
  background: #1f2c4a;
  color: #fff;

  h3 {
    padding: 0 rem-calc($horizontal-inset);
    font-family: $font-family-barlow;
    font-size: rem-calc(24);
    font-weight: 600;
    line-height: rem-calc(27);
  }

  .options {
    position: absolute;
    display: flex;
    top: rem-calc(20);
    right: rem-calc(24);

    button {
      opacity: 0.7;

      &:not(:last-child) {
        margin-right: rem-calc(19);
      }
    }
  }
  .tabNavigatorContainer {
    margin-top: rem-calc(17 + 12);
    margin-left: rem-calc($horizontal-inset);
  }
}
