@import 'Resources/scss/mixins/rem-calc';
@import 'Resources/scss/utils/variables.scss';

.flag {
  width: 28px;
  height: 20px;

  .flagPlaceholder {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;
    border-radius: 2px;

    font-family: $font-family-barlow;
    font-size: rem-calc(12);
    font-weight: 600;

    color: #fff;
    background: black;
  }
}
