@import 'Resources/scss/utils/variables';
@import 'Resources/scss/mixins/rem-calc';

$horizontal-inset: rem-calc(13);

.layerSelector {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 99;

  user-select: none;

  .control {
    cursor: pointer;
  }

  .menu {
    min-width: rem-calc(180);
    border-radius: rem-calc(5);
    margin-top: rem-calc(10);
    background: #fff;
    overflow: hidden;

    .option {
      padding: rem-calc(7) $horizontal-inset;

      &:hover {
        background: $color-highlighted;
      }
    }

    .menuSection {
      &:last-child {
        padding-bottom: rem-calc(6);
      }

      .menuSectionHeader {
        padding: 0 $horizontal-inset;
        margin-bottom: rem-calc(6);

        .menuSectionHeaderContent {
          font-size: rem-calc(11);
          font-weight: 400;
          line-height: rem-calc(15);
          padding: rem-calc(11) 0;
          text-transform: uppercase;
          letter-spacing: 0.29em;
        }
      }

      .menuSectionBody {
        // Reserved
      }
    }
  }
}
