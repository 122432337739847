@import 'Resources/scss/mixins/rem-calc';

.body {
  padding: rem-calc(30) 0;

  .rowInput {
    width: 100%;
    padding: 0 rem-calc(30);
    display: flex;
    justify-content: space-between;
    margin-bottom: rem-calc(20);

    .input {
      width: rem-calc(320);
      position: relative;

      .headline {
        display: flex;
        justify-content: space-between;

        .title {
          size: rem-calc(13);
          line-height: rem-calc(17);
          margin-bottom: rem-calc(10);

          .optional {
            color: #aaaeb9;
          }
        }

        .errorMessage {
          color: #ff0000;
        }
      }

      input {
        height: rem-calc(42);
        width: 100%;
      }
    }

    .select {
      width: rem-calc(320);

      .rowSelectTitle {
        margin-bottom: rem-calc(10);
      }
    }
  }

  .selectRoles {
    margin-top: rem-calc(20);

    .selectRolesTitle {
      padding: 0 rem-calc(30);
      font-size: rem-calc(13);
    }

    .selectComponents {
      padding: 0 rem-calc(30);
      margin-top: rem-calc(10);
    }
  }

  .rowWrapper {
    margin-top: rem-calc(20);
  }

  .loadingSelect {
    display: flex;
    justify-content: center;
    padding: rem-calc(12) 0;
  }
}

.controlBar {
  display: flex;
  justify-content: flex-end;
}
