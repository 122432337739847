@import 'Resources/scss/mixins/rem-calc';

.loadingPage {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: #1f2c4a;

  .animationContainer {
    display: block;
    width: rem-calc(400);
    height: rem-calc(400);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
