@import 'Resources/scss/mixins/rem-calc';
@import '../../DateTimeRangePickerModal.module.scss';

.dateTimeRangePickerModalBody {
  .dateTimeRangePickerModalBodyCalendarSection {
    position: relative;
    padding: rem-calc(20) 0;

    .separator {
      position: absolute;
      width: 1px;
      top: 0;
      bottom: 0;
      left: 50%;
      background: #ededed;
      z-index: 2;
    }

    .row {
      display: flex;

      .column {
        flex: 1;
        padding: 0 rem-calc(20);
      }
    }

    .calendarBodies {
      $rows: 6;

      position: relative;
      height: rem-calc($rows * ($calendar-cell-width + $calendar-cell-margin-vertical * 2));
      overflow: hidden;

      .calendarBodyWrapper {
        position: absolute;
        top: 0;
        left: 0;
        transition: transform 350ms cubic-bezier(0.35, 0.8, 0.4, 1) 0ms;
      }
    }
  }

  .dateTimeRangePickerModalBodyTimeSection {
    padding: rem-calc(20) 0;

    .row {
      display: flex;
      padding: 0 rem-calc(10);

      .column {
        flex: 1;
        padding: 0 rem-calc(10);
      }
    }

    .timeInputControl {
      p {
        margin-bottom: rem-calc(10);
      }

      .error {
        margin-top: rem-calc(5);
        color: #ff1f00;
        margin-bottom: 0;
      }
    }
  }

  .sectionSeparator {
    position: relative;
    height: 1px;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      height: 1px;
      background: #ededed;
    }
  }
}
