@import 'Resources/scss/mixins/rem-calc';

.emitterDetailsBar {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: rem-calc(420);
  background: #fff;

  .emitterDetailsBarBody {
    flex-grow: 1;
    overflow-y: auto;
    position: relative;
  }

  .emitterDetailsBarSection {
    .emitterDetailsBarSection:not(:last-child) {
      margin-bottom: rem-calc(30);
    }

    .emitterDetailsBarSectionHeader {
      // Reserved for future extension
    }

    .emitterDetailsBarSectionBody {
      padding: rem-calc(20) rem-calc(24);

      .emitterDetailsBarSectionBody {
        padding: rem-calc(20) 0;
        margin-bottom: rem-calc(-20);
      }

      // .emitterDetailsBarSectionHeaderRegular {
      //   // h4 {
      //   //   font-size: rem-calc(13);
      //   //   text-transform: none;
      //   //   letter-spacing: normal;
      //   // }

      //   height: auto;
      //   padding-bottom: rem-calc(11);
      //   padding-left: 0;
      //   padding-right: 0;
      //   background: none;
      //   border-bottom: 1px solid #d3d9e7;

      //   &:before {
      //     content: '';
      //     width: rem-calc(5);
      //     height: rem-calc(5);

      //     // Should be 50% of width, but we made i t 100% of width to prevent rounding error
      //     border-radius: rem-calc(5);
      //     background: #000;
      //     margin-right: rem-calc(6);
      //   }
      // }
    }

    .emitterDetailsBarSectionHeaderRegular {
      display: flex;
      align-items: center;
      padding: 0 rem-calc(24);
      height: rem-calc(37);
      background: #f5f5f5;

      h4 {
        font-size: rem-calc(11);
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 0.29em;
      }
    }
  }
}
