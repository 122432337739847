@import 'Resources/scss/mixins/rem-calc';

.option {
  display: flex;
  align-items: center;
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
  }

  label {
    margin-left: rem-calc(8);
    user-select: none;
    cursor: inherit;
  }
}
