@import 'Resources/scss/mixins/rem-calc';

.dashboardPage {
  height: 100vh;
  background: #212733;

  .controlBarContainer {
    position: fixed;
    z-index: 900;
    bottom: 0;
    left: 50px;
    right: 50px;
  }

  .leftBar {
    position: fixed;
    z-index: 99;
    height: 100%;

    .topLeftAccessory {
      position: absolute;
      top: rem-calc(20);
      left: 100%;
      margin-left: rem-calc(20);
    }

    .bottomLeftAccessory {
      position: absolute;
      bottom: rem-calc(20 + 80);
      left: 100%;
      margin-left: rem-calc(20);
    }
  }

  .rightBar {
    position: fixed;
    top: 0;
    z-index: 800;
    height: 100%;
  }

  .topRightAccessory {
    position: absolute;
    top: rem-calc(20);
    right: rem-calc(20);
    display: flex;

    > :not(:last-child) {
      margin-right: rem-calc(10);
    }
  }

  .bottomRightAccessory {
    position: absolute;
    bottom: rem-calc(20 + 80);
    right: rem-calc(20);
  }
}
