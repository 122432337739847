@import 'Resources/scss/utils/variables';
@import 'Resources/scss/mixins/rem-calc';

.chartContainer {
  // This is intentionally set to px (not rem)
  // because chart size and margin are not dynamic.
  height: 304px;

  .loadingIndicator {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: rem-calc(16);
    height: 100%;
    opacity: 0.4;
  }
}

.unitsControl {
  overflow: hidden;
  margin-bottom: rem-calc(20);

  .unitDropdown {
    display: flex;
    gap: rem-calc(5);
    align-items: center;
    font-weight: 500;
    font-size: rem-calc(14);
    cursor: pointer;
    user-select: none;

    &.left {
      float: left;
    }

    &.right {
      float: right;
    }
  }
}
