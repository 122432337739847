@import 'Resources/scss/utils/variables';
@import 'Resources/scss/mixins/rem-calc';

.chartContainer {
  // This is intentionally set to px (not rem)
  // because chart size and margin are not dynamic.
  height: 290px;
  padding-top: rem-calc(9);

  .loadingIndicator {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: rem-calc(16);
    line-height: rem-calc(24);
    height: 100%;
    opacity: 0.4;
  }
}
