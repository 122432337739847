@import 'Resources/scss/utils/variables';
@import 'Resources/scss/mixins/rem-calc';

.stepBar {
  $border-width: 1px;

  display: flex;
  // position: fixed;

  .stepBarItem {
    position: relative;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: rem-calc(39);
    padding: 0 rem-calc(20);
    font-size: rem-calc(12);
    line-height: rem-calc(16);
    border-bottom: $border-width solid #d3d9e7;
    background: #fff;

    .number {
      color: #6e6e6e;
    }

    &:not(:last-child) {
      border-right: $border-width solid #d3d9e7;
    }

    &.active {
      .title {
        color: $color-primary;
        font-weight: 700;
      }

      &::after {
        content: '';
        position: absolute;
        bottom: -$border-width;
        left: 0;
        right: 0;
        height: rem-calc(2);
        background: $color-primary;
      }
    }
  }
}
