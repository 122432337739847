@import 'Resources/scss/utils/variables';
@import 'Resources/scss/mixins/rem-calc';
@import '../../DateTimeRangePickerModal.module.scss';

.calendarBody {
  width: rem-calc(7 * $calendar-cell-width);

  .row {
    display: flex;

    .cell {
      width: rem-calc($calendar-cell-width);
      height: rem-calc($calendar-cell-width);
      line-height: rem-calc($calendar-cell-width);
      text-align: center;
      cursor: pointer;
      margin: rem-calc($calendar-cell-margin-vertical) 0;

      border: 2px solid transparent;

      &.highlighted {
        background: #eff8fd;
      }

      &:hover {
        background: $color-highlighted;
      }

      &.previewed {
        $preview-border-style: 2px dashed rgba(0, 0, 0, 0.128);

        border-top: $preview-border-style;
        border-bottom: $preview-border-style;

        &:first-of-type {
          border-left: $preview-border-style;
        }

        &:last-of-type {
          border-right: $preview-border-style;
        }

        &:hover {
          background: transparent;
          border-right: $preview-border-style;
        }
      }

      &.disabled {
        color: #d9d9d9;
        cursor: not-allowed !important;

        &:hover {
          background: transparent;
        }
      }

      &.selected {
        background: $color-primary;
        color: #fff;
        border: 2px solid transparent;

        &:hover {
          background: #39bdff;
        }
      }

      &.hidden {
        color: transparent;
        cursor: default;
        background: transparent;

        &:hover {
          background: transparent;
        }
      }
    }
  }
}
