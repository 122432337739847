@import 'Resources/scss/utils/variables';
@import 'Resources/scss/mixins/rem-calc';

.intervalSelector {
  position: relative;
  user-select: none;

  .control {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    height: rem-calc(13);
    line-height: rem-calc(13);
    gap: rem-calc(12);
    font-size: rem-calc(11);
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }

  .menu {
    position: absolute;
    bottom: rem-calc(13 + 18);
    left: 0;
    z-index: 99;
    width: rem-calc(300);
    margin-top: rem-calc(5);
    color: #000;

    .menuContent {
      background: #fff;
      border-radius: rem-calc(5);
      overflow: hidden;
    }

    &:after {
      content: '';
      position: absolute;
      bottom: rem-calc(-6);
      left: rem-calc(30);
      height: 0;
      border-top: solid rem-calc(6) #fff;
      border-left: solid rem-calc(6) transparent;
      border-right: solid rem-calc(6) transparent;
    }

    .option {
      display: flex;
      align-items: center;
      height: rem-calc(34);
      padding: 0 rem-calc(13);
      cursor: pointer;

      &:hover {
        background: $color-highlighted;
      }

      &.selected {
        background: $color-selected;
      }
    }

    .customDateRangeOption {
      padding: rem-calc(12) rem-calc(13);

      .customDateRangeOptionHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: rem-calc(10);
      }

      .noRealtimeDataInfo {
        padding: rem-calc(10);
        border-radius: rem-calc(4);
        background: #fff9eb;
        border: 1px solid #db9f00;
      }

      :global {
        .form-control:not(:last-child) {
          margin-bottom: rem-calc(10);
        }
      }

      &.selected {
        background: $color-selected;
      }
    }
  }
}
