@import 'Resources/scss/mixins/rem-calc';
@import '../../DateTimeRangePickerModal.module.scss';

.calendarHeader {
  display: flex;
  width: rem-calc($calendar-cell-width * 7);
  padding: rem-calc(7) 0;

  .calendarHeaderCell {
    width: rem-calc($calendar-cell-width);
    text-align: center;
    color: #6e6e6e;
    font-weight: 400;
  }
}
