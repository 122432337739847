@import 'Resources/scss/mixins/rem-calc';

.stepInterval {
  $spacing-horizontal: 30;
  $spacing-vertical: 20;

  padding: rem-calc($spacing-horizontal);

  .row {
    display: flex;
    gap: rem-calc($spacing-horizontal);

    &:not(:last-child) {
      margin-bottom: rem-calc($spacing-vertical);
    }

    .column {
      flex: 1;
    }
  }
}
