@import 'Resources/scss/mixins/rem-calc';

.toast {
  $prefix-width: 40;
  $suffix-width: 42;

  margin-left: auto;
  margin-right: auto;
  border-radius: rem-calc(5);
  overflow: hidden;
  pointer-events: auto;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.25);

  @extend .success;

  &.success {
    background: #ebfff8;

    .toastContent .prefix {
      background: #00dd8d;
    }
  }

  &.danger {
    background: #fff0ee;

    .toastContent .prefix {
      background: #ff1f00;
    }
  }

  @keyframes slidein {
    0% {
      transform: translate3d(0, -200%, 0) scale(0.86);
      opacity: 0.5;
    }
    100% {
      transform: translate3d(0, 0, 0) scale(1);
      opacity: 1;
    }
  }

  @keyframes slideout {
    0% {
      transform: translate3d(0, 0, -1px) scale(1);
      opacity: 1;
    }
    100% {
      transform: translate3d(0, -150%, -1px) scale(0.86);
      opacity: 0;
    }
  }

  &.slidein {
    animation: slidein 0.35s cubic-bezier(0.21, 1.02, 0.73, 1) forwards;
  }

  &.slideout {
    animation: slideout 0.4s forwards cubic-bezier(0.06, 0.71, 0.55, 1);
  }

  .toastContent {
    display: flex;
    align-items: center;
    position: relative;
    padding-left: rem-calc($prefix-width);
    padding-right: rem-calc($suffix-width);
    height: rem-calc(40);

    .prefix {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: rem-calc($prefix-width);
      height: 100%;
    }

    .body {
      padding: 0 rem-calc(14);
    }

    .suffix {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: rem-calc($suffix-width);
      height: 100%;
    }
  }
}
