@import 'Resources/scss/utils/variables';
@import 'Resources/scss/mixins/rem-calc';

.detailsBarHeader {
  $horizontal-inset: 22;

  position: relative;
  padding-top: rem-calc(17);
  background: $color-primary;
  color: #fff;

  .title {
    display: flex;
    align-items: center;
    padding: 0 rem-calc($horizontal-inset);

    h3 {
      font-family: $font-family-barlow;
      font-size: rem-calc(24);
      font-weight: 600;
      line-height: rem-calc(27);
      max-width: rem-calc(230);
      text-wrap: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .connectionLabel {
      display: flex;
      align-items: center;
      gap: rem-calc(6);
      margin-left: rem-calc(10);
      padding: rem-calc(4) rem-calc(5);
      border-radius: rem-calc(4);
      font-family: $font-family-barlow;
      font-size: rem-calc(13);
      line-height: rem-calc(16);
      background: rgba(255, 255, 255, 0.17);

      .indicator {
        display: inline-block;

        $radius: 3px;
        width: $radius * 2;
        height: $radius * 2;
        border-radius: $radius;

        &.lowData {
          background: #ff8c00;
        }

        &.offline {
          background: #b2b7c7;
        }
      }
    }
  }

  .coordinate {
    padding: 0 rem-calc($horizontal-inset);
    margin-top: rem-calc(12);
    font-size: rem-calc(12);
    line-height: rem-calc(16);
    letter-spacing: 0.3em;
  }

  .options {
    position: absolute;
    display: flex;
    top: rem-calc(20);
    right: rem-calc(24);

    > button {
      opacity: 0.7;

      &:not(:last-child) {
        margin-right: rem-calc(19);
      }
    }
  }

  .sectionNavigatorContainer {
    margin-top: rem-calc(17);
  }
}
