@use 'sass:math';
@import 'Resources/scss/mixins/rem-calc';

.aqiLegend {
  $height: 21;

  height: rem-calc($height);
  background: #fff;
  display: flex;
  font-size: rem-calc(12);
  border-radius: rem-calc(math.div($height, 2));

  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 rem-calc(10);
  }

  .scoreBar {
    display: flex;
    align-items: flex-start;
    height: 100%;
    background: linear-gradient(90deg, #7cc045 0%, #ffdf01 33.33%, #ee7d03 66.67%, #bf2e3d 100%);
    border-top-right-radius: rem-calc(math.div($height, 2));
    border-bottom-right-radius: rem-calc(math.div($height, 2));

    .scoreBarItem {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: rem-calc(45);
      height: 100%;
      cursor: pointer;

      .scrollBarItemLabel {
        color: #fff;
        text-shadow: 0px 0px 7px rgba(0, 0, 0, 1);
        font-weight: 600;
      }

      &:hover {
        .scrollBarItemTooltip {
          display: block;
        }
      }

      .scrollBarItemTooltip {
        position: absolute;
        display: flex;
        bottom: rem-calc(-15);
        left: 50%;
        display: block;
        padding: rem-calc(3) rem-calc(7);
        border-radius: rem-calc(3);
        text-align: center;
        text-wrap: nowrap;
        background: #fff;
        transform: translate(-50%, 100%);
        display: none;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 50%;
          margin-left: rem-calc(-7);
          margin-top: rem-calc(-7);
          width: 0;
          z-index: 1;
          height: 0;
          border-bottom: solid rem-calc(7) #fff;
          border-left: solid rem-calc(7) transparent;
          border-right: solid rem-calc(7) transparent;
        }
      }
    }
  }
}
