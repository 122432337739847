@import 'Resources/scss/mixins/rem-calc';

.button {
  position: relative;
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
  height: rem-calc(45);
  padding: 0 rem-calc(20);
  border-radius: rem-calc(5);
  font-weight: 500;
  text-align: center;

  &.primary {
    background: #0093dd;
    color: #fff;

    &:disabled {
      background: #d9dcdd;
    }
  }

  &.secondary {
    background: #fff;
    border: 1px solid #d3d9e7;

    &:disabled {
      color: #d8d8d8;
      border: 1px solid #d8d8d8;
    }
  }

  &.danger {
    background: #ff1f00;
    color: #fff;

    &:disabled {
      background: #dedad9;
    }
  }

  .loader {
    display: flex;
    justify-content: center;
    width: 100%;
    position: absolute;
    margin-left: rem-calc(
      -20
    ); // to support the padding so can work both with the text and the loading indicator
  }

  &.isLoading {
    color: rgba(0, 0, 0, 0);
  }

  &:disabled {
    pointer-events: none;
  }
}
