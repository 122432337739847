@import 'Resources/scss/utils/variables';
@import 'Resources/scss/mixins/rem-calc';

.modalWrapper {
  position: fixed;
  z-index: 1000;
  background: rgba(32, 38, 44, 0.7);
  height: 100vh;
  width: 100%;
  display: grid;
  place-content: center;

  .modal {
    display: flex;
    flex-direction: column;
    width: rem-calc(730);
    max-height: calc(100vh - 40px);
    z-index: 999;
    border-radius: rem-calc(5);
    overflow: hidden;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: rem-calc(23) rem-calc(30) rem-calc(22) rem-calc(26);
      background: $color-primary;

      .headerText {
        color: white;
        h1 {
          font-weight: 700;
          font-size: rem-calc(19);
          line-height: rem-calc(25);
        }

        h2 {
          font-size: rem-calc(13);
          line-height: rem-calc(17);
          margin-top: rem-calc(3);
        }
      }

      .closeButton {
        &:disabled {
          opacity: 0.3;
        }
      }
    }

    .body {
      flex-grow: 1;
      position: relative;
      background: #fff;
      overflow-y: auto;

      &.disableScroll {
        overflow: hidden;
      }
    }

    .overlayWrapper {
      position: relative;

      &.disableBody::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(255, 255, 255, 0.7);
        pointer-events: none;
        z-index: 1000;
      }
    }

    .footer {
      padding: rem-calc(18) rem-calc(30);
      background: #f5f5f5;
    }
  }
}
