@import 'Resources/scss/mixins/rem-calc';

.videoPlayerPopupOverlay {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.videoPlayerPopup {
  $width: 800;

  position: relative;
  width: rem-calc($width);
  height: rem-calc(0.75 * $width);
  max-width: calc(100vw - rem-calc(40));
  max-height: calc(100vh - rem-calc(40));
  border-radius: rem-calc(5);
  background: #000;
  overflow: hidden;

  .errorMessage {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
  }

  .header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: rem-calc(12) rem-calc(17) rem-calc(77) rem-calc(17);
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAACTCAYAAABPu3PBAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABZSURBVHgBlZFRDsAgCEOb7f4n2Rmt2/zRmFLQj0bktTEAAM/1CX/pVihus6TlNqTlUYySw26LuH7sqML+49VpsDhnvxQIR/imopCXsDYIDpZTtqR7O8ci47yR73Im325yWQAAAABJRU5ErkJggg==');
    background-size: contain;

    .title {
      font-size: rem-calc(16);
      font-weight: 700;
      color: #fff;
    }
  }

  :global {
    .video-js.vjs-paused:not(.vjs-has-started) .vjs-loading-spinner {
      display: block;
      visibility: visible;
    }

    .video-js.vjs-paused:not(.vjs-has-started) .vjs-loading-spinner,
    .video-js.vjs-paused:not(.vjs-has-started) .vjs-loading-spinner {
      -webkit-animation: vjs-spinner-spin 1.1s cubic-bezier(0.6, 0.2, 0, 0.8) infinite,
        vjs-spinner-fade 1.1s linear infinite;
      animation: vjs-spinner-spin 1.1s cubic-bezier(0.6, 0.2, 0, 0.8) infinite,
        vjs-spinner-fade 1.1s linear infinite;
    }
  }
}
