@import 'Resources/scss/mixins/rem-calc';

.dateTimeRangePickerInput {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  height: rem-calc(42);
  padding: 0 rem-calc(13);
  border-radius: rem-calc(5);
  border: 1px solid #d3d9e7;
  background: #fff;
  cursor: pointer;

  &.disabled {
    border: 1px solid #e1e6f0;
    background: #fbfcfd;
    cursor: not-allowed;
    color: #c5cee0;

    .placeholder {
      color: #fff;
    }
  }

  &.error {
    border-color: #ff1f00;
  }

  .placeholder {
    color: #abb3c5;
  }

  .expandIndicator {
    margin-left: rem-calc(9);
  }
}
