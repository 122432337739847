@import 'Resources/scss/utils/variables';
@import 'Resources/scss/mixins/rem-calc';

.propertySelectorContainer {
  position: relative;

  .propertySelector {
    display: flex;
    align-items: center;
    height: rem-calc(37);
    padding: 0 rem-calc(13);
    border: 1px solid #d3d9e7;
    border-radius: rem-calc(5);
    cursor: pointer;
    user-select: none;

    &:hover {
      border-color: #9397a1;
    }

    &.focused {
      border-color: #009fe3;
    }

    .content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      pointer-events: none;

      .title {
        display: flex;
        align-items: center;

        .placeholder {
          color: #8b93a4;
        }

        .colorIndicator {
          margin-right: rem-calc(7);
          width: rem-calc(12);
          height: 2px;

          &.blue {
            background: $color-primary;
          }
          &.green {
            background: $color-secondary;
          }
        }
      }
    }
  }

  .menuPopup {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 999;
    margin-top: rem-calc(8);
  }
}
