@import 'Resources/scss/utils/variables';
@import 'Resources/scss/mixins/rem-calc';

.optionGrid {
  $option-padding: 20;
  $color-separator: #d3d9e7;

  overflow: hidden;

  .optionGridContent {
    display: flex;
    flex-wrap: wrap;

    .option {
      position: relative;
      display: flex;
      min-width: 25%;
      height: rem-calc(80);
      padding: 0 rem-calc(30);
      align-items: center;

      &:hover {
        background: $color-highlighted;
        cursor: pointer;
      }

      &.disabled {
        background: none !important;
        cursor: not-allowed;
      }

      &:before {
        content: '';
        position: absolute;
        top: rem-calc($option-padding);
        bottom: rem-calc($option-padding);
        right: 0;
        width: 1px;
        background: $color-separator;
      }

      &:nth-child(4n) {
        &:before {
          width: 0;
        }
      }

      &:after {
        content: '';
        position: absolute;
        bottom: -1px;
        left: 0;
        right: 0;
        height: 1px;
        background: $color-separator;
      }

      &:nth-child(4n) {
        &:after {
          right: rem-calc($option-padding);
        }
      }

      &:nth-child(4n + 1) {
        &:after {
          left: rem-calc($option-padding);
        }
      }
    }
  }
}
