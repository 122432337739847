@import 'Resources/scss/utils/variables';
@import 'Resources/scss/mixins/rem-calc';

.sideBar {
  position: fixed;
  top: 0;
  left: 0;
  width: rem-calc(224);
  height: 100vh;
  background: #fff;
  display: flex;
  flex-direction: column;

  .sideBarHeader {
    flex-grow: 0;
    display: flex;
    position: relative;

    $height: rem-calc(46);

    height: $height;
    min-height: $height;
    color: white;
    background: #1f2c4a;

    .yellowBar {
      width: 8px;
      background: #fff500;
    }

    .blueBar {
      width: 8px;
      background: #0093dd;
    }

    .logoContainer {
      display: flex;
      align-items: center;
      padding-left: rem-calc(16);

      sub {
        font-size: rem-calc(7.5);
        text-transform: uppercase;
        margin-left: rem-calc(7);
        margin-top: rem-calc(5);
        font-weight: 500;
        letter-spacing: 0.2em;
      }
    }
  }

  .sideBarBody {
    flex: 1;
    overflow-y: auto;
  }

  .sideBarFooter {
    .downloadReportButton {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: rem-calc(50);
      background: $color-primary;
      color: #fff;
      padding: 0 rem-calc(20);
      &:disabled {
        pointer-events: none;
        background: #d9dcdd;
      }
    }
  }
}
