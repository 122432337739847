@import 'Resources/scss/mixins/rem-calc';
@import 'Resources/scss/utils/variables';

.userMenu {
  position: absolute;
  bottom: rem-calc(10);
  right: rem-calc(-220);
  z-index: 999;

  ul {
    width: rem-calc(200);
    background: #fff;
    border-radius: rem-calc(4);
    overflow: hidden;

    li {
      font-size: rem-calc(14);
      padding: rem-calc(10);
      list-style: none;
      display: flex;
      align-items: center;
      gap: rem-calc(10);
      cursor: pointer;

      &:hover {
        background: $color-highlighted;
      }

      div {
        width: rem-calc(22);
        display: flex;
        justify-content: center;
      }

      &.logout {
        border-top: 1px solid $color-separator;
      }
    }
  }

  .leftTriangle {
    width: 0;
    height: 0;
    border-top: rem-calc(10) solid transparent;
    border-bottom: rem-calc(10) solid transparent;
    border-right: rem-calc(10) solid #fff;
    cursor: auto;
    position: absolute;
    left: rem-calc(-10);
    bottom: rem-calc(12);
  }
}
