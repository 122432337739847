@import 'Resources/scss/utils/variables';
@import 'Resources/scss/mixins/rem-calc';

$horizontal-inset: rem-calc(20);

.emitterSelector {
  padding: rem-calc(16) 0;

  .emitterSelectorHeader {
    flex-grow: 0;
    padding: 0 $horizontal-inset;
    margin-bottom: rem-calc(8);

    .emitterSelectorHeaderContent {
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-transform: uppercase;

      font-size: rem-calc(11);
      font-weight: 400;
      line-height: rem-calc(15);
      letter-spacing: 0.29em;

      padding: rem-calc(9) 0;

      .emitterSelectorHeaderAccessory {
        flex-shrink: 0;
      }
    }
  }

  .emitterSelectorBody {
    .emitterSelectorItem {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: rem-calc(56);
      padding: 0 $horizontal-inset;
      font-size: rem-calc(16);
      font-family: $font-family-barlow;
      gap: rem-calc(15);

      &.disabled {
        color: #b2b7c7;
      }

      .emitterSelectorItemAccessory {
        flex-shrink: 0;
      }
    }
  }
}
