@import 'Resources/scss/utils/variables';
@import 'Resources/scss/mixins/rem-calc';

.optionPopup {
  position: absolute;
  background: #fff;
  padding: rem-calc(2);
  border-radius: rem-calc(5);
  min-width: rem-calc(120);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

  .option {
    border-radius: rem-calc(4);
    cursor: pointer;
    padding: rem-calc(10);

    &:hover {
      background: $color-highlighted;
    }

    &.selected {
      background: $color-selected;
    }
  }

  &.down:after {
    border-bottom: solid rem-calc(5) #fff;
    top: 0;
    transform: translate(var(--arrow-left), rem-calc(-5));
  }

  &.up:after {
    border-top: solid rem-calc(5) #fff;
    bottom: 0;
    transform: translate(var(--arrow-left), rem-calc(5));
  }
}

.optionPopup:after {
  content: '';
  position: absolute;
  left: rem-calc(5);
  margin-left: rem-calc(-5);
  width: 0;
  z-index: 1;
  height: 0;
  border-left: solid rem-calc(5) transparent;
  border-right: solid rem-calc(5) transparent;
}
