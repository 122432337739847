@import 'Resources/scss/mixins/rem-calc';

.optionSectionHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 rem-calc(24);
  height: rem-calc(37);
  background: #f5f5f5;

  h4 {
    font-size: rem-calc(11);
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.29em;
  }

  .optionToggle {
    display: flex;
    align-items: center;
    gap: rem-calc(10);
    cursor: pointer;
    user-select: none;
    font-weight: 700;
  }
}
