@import 'Resources/scss/utils/variables';
@import 'Resources/scss/mixins/rem-calc';

.tooltip {
  padding: rem-calc(12);
  background: #fff;
  box-shadow: 0 0 rem-calc(10) 0 rgba(0, 0, 0, 0.1);
  border-radius: rem-calc(5);

  .header {
    font-size: rem-calc(16);
    font-weight: 600;
  }

  hr {
    margin-top: rem-calc(10);
    margin-bottom: rem-calc(10);
  }

  .timestamp {
    margin-left: rem-calc(4);
    color: #6e6e6e;
  }
}
