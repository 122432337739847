@import 'Resources/scss/mixins/rem-calc';

.usersTable {
  $gutter: 20;
  $inset-left: 27;
  $name-column-width: 249;
  $email-column-width: 249;
  $date-column-width: 204;

  $row-padding-vertical: 12;

  display: flex;
  flex-direction: column;
  overflow-y: auto;
  flex-grow: 1;

  .tableHeader {
    display: flex;
    font-size: rem-calc(13);
    font-weight: 700;
    line-height: rem-calc(17.2);
    color: #6e6e6e;
    padding: rem-calc(10) 0;
    border-top: rem-calc(1) solid #d3d9e7;
    border-bottom: rem-calc(1) solid #d3d9e7;

    .name {
      width: rem-calc($name-column-width);
      padding-left: rem-calc($inset-left);
      padding-right: rem-calc($gutter);
    }

    .email {
      width: rem-calc($email-column-width);
      padding-right: rem-calc($gutter);
    }

    .dateCreated {
      width: rem-calc($date-column-width);
      padding-right: rem-calc($gutter);
    }
  }

  .tableContent {
    flex-grow: 1;
    overflow-y: auto;

    .tableRow {
      display: flex;
      align-items: center;
      border-bottom: rem-calc(1) solid #d3d9e7;
      padding: rem-calc($row-padding-vertical) 0;

      .name {
        width: rem-calc($name-column-width);
        padding-left: rem-calc($inset-left);
        padding-right: rem-calc($gutter);
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .email {
        width: rem-calc($email-column-width);
        padding-right: rem-calc($gutter);
        color: #6e6e6e;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .dateCreated {
        width: rem-calc($date-column-width);
        padding-right: rem-calc($gutter);
        color: #6e6e6e;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .menu {
        position: relative;
        flex-grow: 1;

        .iconDots {
          cursor: pointer;
        }
      }
    }

    .loadingNextPageIndicatorRow {
      display: flex;
      justify-content: center;
      padding: rem-calc($row-padding-vertical) 0;
    }
  }
}
