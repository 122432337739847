@import 'Resources/scss/mixins/rem-calc';

.rowSelect {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: rem-calc(10);

  &:not(:last-child) {
    margin-bottom: rem-calc(20);
  }

  .namespaceCheckbox {
    .wrapper {
      cursor: pointer;
      display: flex;
      align-items: center;
      width: max-content;

      span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: rem-calc(150);
      }

      input {
        margin: 0;
        width: rem-calc(20);
        height: rem-calc(20);
        border: 1px solid #b2b7c7;
        border-radius: rem-calc(3);
        cursor: pointer;

        &:checked {
          accent-color: #0093dd;
        }
      }

      span {
        font-size: rem-calc(13);
        margin-left: rem-calc(10);
      }
    }
  }

  .selectPermission {
    flex-grow: 1;
  }
}

.deleteButton {
  padding: rem-calc(10) rem-calc(12);
  border-radius: rem-calc(5);
  border: 1px solid #cccccc;
  margin-top: auto;
}
