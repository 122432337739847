@import 'Resources/scss/mixins/rem-calc';

$calendar-cell-width: 36;
$calendar-cell-margin-vertical: 1;

.dateTimeRangePickerModalFooter {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: rem-calc(20);
}
