@import 'Resources/scss/mixins/rem-calc';

.contentTable {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;

  .head {
    display: flex;
    line-height: rem-calc(17.2);
    padding: rem-calc(10) 0;
    align-items: center;
    border-top: rem-calc(1) solid #d3d9e7;
    border-bottom: rem-calc(1) solid #d3d9e7;

    div {
      &.userProfile {
        width: rem-calc(210);
        margin-left: rem-calc(22);
      }

      &.namespace {
        width: rem-calc(156);
        margin-left: rem-calc(20);
      }

      &.role {
        width: rem-calc(152);
        margin-left: rem-calc(20);
      }

      &.option {
        width: auto;
        margin-left: rem-calc(20);
      }

      span {
        font-size: rem-calc(13);
        font-weight: bold;
        color: #6e6e6e;
      }
    }
  }
  .body {
    $row-padding-vertical: 12;

    flex-grow: 1;
    overflow-y: auto;

    .loadingNewData {
      background: #f1f3f7;
      border-bottom: rem-calc(1) solid #d3d9e7;
      display: flex;
      justify-content: center;
      padding: rem-calc($row-padding-vertical) 0;
    }

    .loadingNextPageIndicatorRow {
      display: flex;
      justify-content: center;
      padding: rem-calc($row-padding-vertical) 0;
    }
  }
}
