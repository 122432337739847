@import 'Resources/scss/mixins/rem-calc';
@import 'Resources/scss/utils/variables';

$option-padding: 20;
$color-separator: #d3d9e7;

.stepSensor {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;

  .option {
    position: relative;
    display: flex;
    width: 33.3333%;
    height: rem-calc(80);
    padding: 0 rem-calc(30);
    align-items: center;
    gap: rem-calc(15);

    &:hover {
      background: $color-highlighted;
      cursor: pointer;
    }

    &.disabled {
      background: none !important;
      cursor: not-allowed;
    }

    &:before {
      content: '';
      position: absolute;
      top: rem-calc($option-padding);
      bottom: rem-calc($option-padding);
      right: 0;
      width: 1px;
      background: $color-separator;
    }

    &:nth-child(3n) {
      /* Change from 4n to 3n */
      &:before {
        width: 0;
      }
    }

    &:after {
      content: '';
      position: absolute;
      bottom: -1px;
      left: 0;
      right: 0;
      height: 1px;
      background: $color-separator;
    }

    &:nth-child(3n) {
      /* Change from 4n to 3n */
      &:after {
        right: rem-calc($option-padding);
      }
    }

    &:nth-child(3n + 1) {
      /* Change from 4n + 1 to 3n + 1 */
      &:after {
        left: rem-calc($option-padding);
      }
    }
  }
}
