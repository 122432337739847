@import 'Resources/scss/mixins/rem-calc';

.alertPopup {
  .title {
    display: flex;
    align-items: center;
    gap: rem-calc(10);
    font-size: rem-calc(15);
    font-weight: 700;
    padding-bottom: rem-calc(10);
    border-bottom: 1px solid #d3d9e7;
    margin-bottom: rem-calc(10);
  }

  .alertContent {
    max-width: rem-calc(200);
    display: flex;
    flex-direction: column;
    gap: rem-calc(8);
  }

  .date {
    :nth-child(2) {
      font-weight: 700;
    }
  }
}
