.toaster {
  position: fixed;
  z-index: 99999;
  pointer-events: none;

  $inset: 16px;

  top: $inset;
  right: $inset;
  bottom: $inset;
  left: $inset;
}
