@import 'Resources/scss/mixins/rem-calc';

.controlBar {
  background: #1f2c4a;
  padding: rem-calc(10) rem-calc(20) rem-calc(10) rem-calc(20);
  height: rem-calc(80);
  color: #fff;
  z-index: 9;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .controlBarHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
