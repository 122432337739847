@import 'Resources/scss/utils/variables';
@import 'Resources/scss/mixins/rem-calc';

.chartSectionHeader {
  .controlRows {
    .controlRow {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: green;
      height: rem-calc(37);
      padding: 0 rem-calc(24);
      background: #f5f5f5;

      &:first-child {
        border-bottom: 1px solid rgba(#d3d9e7, 0.7);
      }
    }

    .propertyPicker {
      position: relative;
      display: flex;
      align-items: center;
      font-weight: 700;
      text-overflow: ellipsis;
      white-space: nowrap;
      height: 100%;
      cursor: pointer;
      user-select: none;

      .colorIndicator {
        margin-right: rem-calc(7);
        width: rem-calc(12);
        height: 2px;

        &.blue {
          background: $color-primary;
        }
        &.green {
          background: $color-secondary;
        }
      }

      .expandIndicator {
        margin-left: rem-calc(10);
        pointer-events: none;
      }
    }

    .unitPicker {
      display: flex;
      align-items: center;
      gap: rem-calc(10);
      font-weight: 700;
      cursor: pointer;
      user-select: none;
    }
  }
}
