@import 'Resources/scss/utils/variables';
@import 'Resources/scss/mixins/rem-calc';

.checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: rem-calc(20);
  height: rem-calc(20);
  background: #fff;
  border-radius: rem-calc(3);
  border: 1px solid #b2b7c7;
  cursor: pointer;

  &.checked {
    background: $color-primary;
    border: 0;
  }

  &.disabled {
    background: #e2e4e8;
    border: 0;
    cursor: not-allowed;
  }
}
